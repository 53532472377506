import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static values = {
    siteKey: String,
  }

  connect() {
    this.form = this.element.closest("form")
    this.form.addEventListener("submit", this.handleSubmit.bind(this))
    this.loadScript()
  }

  loadScript() {
    const script = document.createElement("script")
    script.src = "https://smartcaptcha.yandexcloud.net/captcha.js?render=onload"
    script.defer = true
    script.onload = this.initializeCaptcha.bind(this)
    document.head.appendChild(script)
  }

  initializeCaptcha() {
    if (!window.smartCaptcha) return

    window.smartCaptcha.render("captcha-container", {
      sitekey: this.siteKeyValue,
      invisible: true,
      shieldPosition: "bottom-right",
      callback: this.callback.bind(this),
    })
  }

  callback() {
    this.form.submit()
  }

  handleSubmit(event) {
    event.preventDefault()

    if (!window.smartCaptcha) return

    window.smartCaptcha.execute()
  }
}
